// 👇 It's important to not import @valu/react-valu-search directly here but
// only the subpackage `lazy`.  This ensures the main entry bundle won't get
// bloated by RVS.
import { LazyValuSearch, select } from "@findkit/pink-ocelot/lazy";

const loader = new LazyValuSearch({
    // import the file exporting the ValuSearch instance as the default export
    load: () => import("./search-ui"),
});

/**
 * INSTRUCTIONS:
 * This loader.tsx and valu-search.tsx are enough to connect valu search
 * to a button that open search. RVS will open in full screen modal.
 *
 * If you need to open search in partial modal, or customize the search,
 * check the docs in README.
 */

loader.init(() => {
    const riistaButton = select("#valu-search-open", HTMLAnchorElement, {
        nullable: true,
    });

    const metsastajaButton = select(
        "#valu-search-open-metsastajalehti",
        HTMLButtonElement,
        { nullable: true },
    );

    const riistainfoButton = select(".search-toggle", HTMLButtonElement, {
        nullable: true,
    });

    if (riistaButton) {
        riistaButton.addEventListener("click", event => {
            event.preventDefault();
            loader.activate();
        });

        riistaButton.addEventListener("keydown", event => {
            if (event.key === "Enter") {
                loader.activate();
            }
        });
    }

    if (metsastajaButton) {
        metsastajaButton.addEventListener("click", event => {
            event.preventDefault();
            loader.activate();
        });
    }

    if (riistainfoButton) {
        riistainfoButton.addEventListener("click", event => {
            event.preventDefault();
            loader.activate();
        });
    }

    return vs => {
        vs.initModal();
    };
});
